import { CancelTokenSource } from 'axios';
import { IAllProps as TinyMCEEditorProps } from '@tinymce/tinymce-react';
import { ClassNameProp, ResponseErrorWithHandled } from '../types';
import { RequestStatus } from '../enums/request-status';
import { MergeTagOption } from '../components/overlay-merge-tags';
import {
  AttachmentDeletePayload,
  AttachmentUploadPayload,
  AttachmentUploadResponse,
} from '../../components/sequence/types';
import { ReplyForwardEditorAction } from '../../components/unified-inbox/type';

export type EditorProps = Partial<Pick<TinyMCEEditorProps, 'disabled'>> &
  Pick<TinyMCEEditorProps, 'value' | 'onEditorChange'> &
  ClassNameProp & {
    error?: boolean;
    onEditorBlur?: () => void;
    showMergeAndVariableTags?: boolean;
    mergeTags?: MergeTagOption[];
    imageUploader?: ({ formData, onUploadProgress }) => any;
    onEditorInit?: () => void;
    getEditorInstance?: (editor: any) => void;
    shouldRenderTemplate?: boolean;
    showTemplateModal?: () => void;
    onKeyUp?: (e: any) => void;
    addMergeTagInContent?: (option: any) => void;
    addVariableTagInContent?: (tag: string) => void;
    addSpintaxInContent?: (tag: string) => void;
    showAddAttachments: boolean;
    attachments?: AttachmentType[];
    onAttachmentStatusChange?: (attachment: AttachmentType) => void;
    onAttachmentsChange?: (
      attachments: AttachmentType[],
      isAttachmentDeleted?: boolean,
    ) => void;
    sendAttachmentUploadRequest?: (payload: AttachmentUploadPayload, uniqueId?: number) => void; //UniqueId was added to handle concurrent API calls
    uploadedAttachment?: AttachmentUploadResponse;
    uploadAttachmentRequestStatus?: RequestStatus;
    uploadAttachmentRequestError?: ResponseErrorWithHandled;
    uploadAttachmentRequestMessage?: string;
    resetUploadAttachmentRequest?: (uniqueId?: number) => void;
    errorMessage?: string;
    sendDeleteAttachmentRequest?: (payload: AttachmentDeletePayload, uniqueId?: number) => void;
    deleteAttachmentRequestStatus?: RequestStatus;
    deleteAttachmentRequestError?: ResponseErrorWithHandled;
    deletedAttachmentId?: number;
    resetDeleteAttachmentRequest?: (uniqueId?: number) => void;
    onAttachmentsMetaChange?: (
      attachmentsMeta: AttachmentMetaDataType[],
    ) => void;
    isTemplateEditorModal?: boolean;
    sequenceStepVariantId?: number;
    spintaxOptions?: SpintaxOption[];
    mainList?: MainList[];
    showSpintaxDropdown?: boolean;
    disabledVariableTags?: string[];
    tagsDropdownClassName?: string;
    attachmentsClassname?: string;
    uniqueId?: number;
    action?: ReplyForwardEditorAction
  };

export type MainList = {
  key: number;
  name: string;
  showInsertNewSpintax: boolean;
};
export type SpintaxOption = {
  key: number;
  name: string;
  category: string;
};

export enum AttachmentUploadStatus {
  Upload = 'upload',
  Uploading = 'uploading',
  UploadFailed = 'upload_failed',
  UploadSuccess = 'upload_success',
  Existing = 'existing',
  Deleting = 'deleting',
}

export type AttachmentType = {
  attachmentId: number;
  attachmentIdentifier?: string;
  status: AttachmentUploadStatus;
  file: any;
  attachmentSize?: number;
};

export type AttachmentMetaDataType = {
  attachmentIdentifier: string;
  percentage: number;
  source: CancelTokenSource;
};

export type TagsOptionsForInlineDropdown = {
  text: string;
  value: string;
  group?: string;
  type: 'merge tag' | 'variable tag';
}[];
