import React, { useState, useEffect, useRef } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { useHistory } from 'react-router';

import type { IProps } from './types';
import { UserAndTeamsTabs } from '../../../../enums/users-and-teams';

import UsersAndTeamsHeader from '../users-and-teams-header';
import Users from '../users';
import Teams from '../teams';
import { UsersFilter } from '../../../../types/users-and-teams';
import { Order } from '../../../../../../shared/enums/order';
import UsersAndTeamsEmptyScreen from '../users-and-teams-empty-screen';
import UsersAndTeamsExtraContent from '../users-and-teams-extra-content';
import TeamsPlanBasedRestriction from '../teams-plan-based-restriction';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import {
  SubscriptionPlanTitle,
  SubscriptionPlans,
} from '../../../../../../shared/utils/subscription-plans';
import { planError } from '../../../../../../shared/utils/errors/plan-permission-error/plan-error';

import toaster, { Theme } from '../../../../../../shared/toaster';
import NoSeatAvailableForTeamModal from '../no-seat-available-for-team-modal';
import RestrictionErrorModal from '../../../../../../shared/components/restriction-error-modal';
import PremiumFeatureModal from '../../../../../prospect/components/prospect-list/components/modals/restriction-error-modal';
import PlanRestrictionErrorModal from '../../../../../prospect/components/prospect-list/components/modals/restriction-error-modal';
import { Images } from '../../../../../../shared/app-constants';
import { basicTrailPlanRestriction } from '../../../../../../shared/utils/mail-body-contents';
import { shouldShowUpgradePlanModal } from '../../utils/should-show-upgrade-plan-modal';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';
import store from '../../../../../../store';
import HeaderBanner from '../../../header-banner';
import { AnalyticsEvents } from '../../../../../../shared/enums/analytics';
import { useTranslation } from 'react-i18next';

const UsersAndTeamsContent: React.FC<IProps> = ({
  sendGetUsersRequest,
  resetGetUsersRequest,
  resetGetTeamsRequest,

  showLoading,
  hideLoading,

  users,
  teams,

  getUsersRequestStatus,
  getTeamsRequestStatus,

  adminFirstName,
  userName,

  sendGetConnectedUsersAndEmailAccountsRequest,
  resetConnectedUsersAndEmailAccountsRequest,
  getConnectedUsersAndEmailAccountsRequestStatus,
  getConnectedUsersAndEmailAccountsRequestError,
  trackingId,
  planName,

  ...props
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const tableRef = useRef<any>(null);
  const teamTableRef = useRef<any>(null);

  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [restrictionErrorModalMeta, setRestrictionErrorModalMeta] = useState({
    show: false,
    planName: SubscriptionPlanTitle.EmailOutreachPro,
  });
  const [premiumFeatureMeta, setPremiumFeatureMeta] = useState({
    show: false,
    title: '',
    body: [],
  });
  const [isRestrictionModalShow, setIsRestrictionModalShow] = useState<boolean>(
    false,
  );

  const [
    noSeatAvailableForTeamModal,
    setNoSeatAvailableForTeamModal,
  ] = useState(false);

  const getDefaultActiveTab = (): UserAndTeamsTabs => {
    const paths =
      history.location.pathname.split('/').filter((path) => path) || [];

    if (
      hasPermission(Permissions.TEAM_SHOW) &&
      paths.length === 3 &&
      paths[2] === 'teams'
    ) {
      return UserAndTeamsTabs.Teams;
    }

    if (hasPermission(Permissions.ACCOUNT_USER_READ)) {
      return UserAndTeamsTabs.Users;
    }

    if (hasPermission(Permissions.TEAM_SHOW)) {
      return UserAndTeamsTabs.Teams;
    }

    return null;
  };

  const [activeTab, setActiveTab] = useState<UserAndTeamsTabs>(
    getDefaultActiveTab(),
  );

  const [filters, setFilters] = useState<UsersFilter>({
    pageNum: 1,
    pageSize: 50,
    sortOrder: Order.Asc,
    sortBy: '',
    search: '',
  });
  const [isUsersFilterDirty, setIsUsersFilterDirty] = useState(false);
  const [isTeamsFilterDirty, setIsTeamsFilterDirty] = useState(false);

  const onSendGetUsersRequest = (payload: UsersFilter) => {
    if (hasPermission(Permissions.ACCOUNT_USER_READ)) {
      sendGetUsersRequest(payload);
    }
  };

  const hidePremiumFeatureModal = () => {
    setPremiumFeatureMeta({
      show: false,
      title: '',
      body: [],
    });
  };

  const onSearch = () => {
    onSendGetUsersRequest(filters);
  };

  const onSearchChange = (search: string) => {
    if (!isUsersFilterDirty) {
      setIsUsersFilterDirty(true);
    }
    setFilters({
      ...filters,
      search,
    });
  };

  const onClearSearch = () => {
    if (!isUsersFilterDirty) {
      setIsUsersFilterDirty(true);
    }
    const updatedFilters = {
      ...filters,
      search: '',
    };
    setFilters(updatedFilters);
    onSendGetUsersRequest(updatedFilters);
  };

  const onFilterChange = (payload: UsersFilter) => {
    if (!isUsersFilterDirty) {
      setIsUsersFilterDirty(true);
    }

    const updatedFilters = {
      ...filters,
      ...payload,
    };
    setFilters(updatedFilters);
    onSendGetUsersRequest(updatedFilters);
  };

  const showInviteUserModal = () => {
    const { planCode, totalUsers, slots } = props;

    if (planCode === SubscriptionPlans.FREE) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        planError(2007);
      } else {
        planError(3007);
      }
      return;
    }

    if (
      hasPermission(Permissions.AGENCY_USER_INVITE) &&
      Number(totalUsers) >= slots
    ) {
      setNoSeatAvailableForTeamModal(true);
      return;
    }
    if (
      planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME &&
      Number(totalUsers) >= slots
    ) {
      setRestrictionErrorModalMeta({
        show: true,
        planName: SubscriptionPlanTitle.EmailOutreach,
      });
      return;
    }

    if (
      planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME &&
      Number(totalUsers) >= slots
    ) {
      setRestrictionErrorModalMeta({
        show: true,
        planName: SubscriptionPlanTitle.EmailOutreachPro,
      });
      return;
    }

    if (
      hasPermission(Permissions.AGENCY_USER_INVITE) ||
      hasPermission(Permissions.ACCOUNT_USER_INVITE)
    ) {
      setInviteUserModal(true);
      return;
    }

    if (
      !hasPermission(Permissions.ACCOUNT_USER_INVITE) &&
      shouldShowUpgradePlanModal()
    ) {
      setPremiumFeatureMeta({
        show: true,
        title: 'Upgrade your plan to invite user',
        body: [
          'The Outreach Starter doesn’t allow you to invite users.',
          'Please upgrade to the Outreach Pro or Higher plans to invite users.',
        ],
      });
    }
  };

  const onInviteUserBtnClicked = () => {
    if (hasPermission(Permissions.AGENCY_USER_INVITE)) {
      sendGetConnectedUsersAndEmailAccountsRequest();
    } else {
      showInviteUserModal();
    }
  };

  const hideInviteUserModal = () => {
    setInviteUserModal(false);
  };

  const showAddTeamModal = () => {
    if (!hasPermission(Permissions.ACCOUNT_TEAM_WRITE)) {
      setIsRestrictionModalShow(true);
      // Tripped Feature Event
      window.analytics?.track({
        userId: trackingId,
        event: AnalyticsEvents.TrippedFeature,
        properties: {
          feature_type: 'Create team',
          current_plan: planName,
        },
      });

      return;
    }
    setAddTeamModal(true);
  };

  const hideAddTeamModal = () => {
    setAddTeamModal(false);
  };

  const getTeamsEmptyScreenContent = () => {
    if (hasPermission(Permissions.ACCOUNT_TEAM_INVITE_WRITE)) {
      return {
        title: 'Create Unlimited Teams and unleash the Power of Teamwork',
        desc:
          'Create, Manage and Collaborate to amplify your performance with teams',
      };
    }
    return {
      title: 'You are not currently part of any teams.',
      desc: "Teams will appear once you've been added to one.",
    };
  };

  useEffect(() => {
    if (activeTab === UserAndTeamsTabs.Users) {
      onSendGetUsersRequest(filters);
      history.push('/settings/users');
    }

    if (activeTab === UserAndTeamsTabs.Teams) {
      history.push('/settings/users/teams');
    }
  }, [activeTab]);

  useEffect(
    () => () => {
      resetGetUsersRequest();
      resetGetTeamsRequest();
    },
    [],
  );

  useEffect(() => {
    executeOnRequestStatus({
      status: getUsersRequestStatus,
      onPending: () => {
        showLoading();
      },
      onSuccess: () => {
        hideLoading();
      },
    });
  }, [getUsersRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getTeamsRequestStatus,
      onPending: () => {
        showLoading();
      },
      onSuccess: () => {
        hideLoading();
      },
    });
  }, [getTeamsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getConnectedUsersAndEmailAccountsRequestStatus,
      onSuccess: () => {
        showInviteUserModal();
        resetConnectedUsersAndEmailAccountsRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getConnectedUsersAndEmailAccountsRequestError,
          onError: () => {
            toaster.error(
              getConnectedUsersAndEmailAccountsRequestError.message,
              { theme: Theme.New, showCloseIcon: true, delay: 10000 },
            );
          },
        });
      },
    });
  });

  const isGetUsersRequestLoading = getIsRequestPending(getUsersRequestStatus);
  const isGetTeamsRequestLoading = getIsRequestPending(getTeamsRequestStatus);

  const {
    home: { subscription },
  } = store.getState();

  const renderUsersTab = () => (
    <TabPane tab={UserAndTeamsTabs.Users} key={UserAndTeamsTabs.Users}>
      {!isGetUsersRequestLoading &&
        users?.length === 0 &&
        !isUsersFilterDirty && (
          <UsersAndTeamsEmptyScreen
            imrSrc={Images.UserWithBg}
            title="Invite unlimited Users and supercharge your collaboration."
            desc="Harness the power of teamwork by inviting, organizing, and engaging with individuals who drive your success."
          />
        )}

      {!isGetUsersRequestLoading &&
        users?.length === 0 &&
        isUsersFilterDirty && (
          <UsersAndTeamsEmptyScreen
            imrSrc={Images.NoResult}
            title="No users found"
            desc="Sorry, we couldn't find any user that match your desired criteria."
          />
        )}

      <Users
        filters={filters}
        inviteUserModal={inviteUserModal}
        hideInviteUserModal={hideInviteUserModal}
        onFilterChange={onFilterChange}
        tableRef={(refValue: any) => (tableRef.current = refValue)}
        setPremiumFeatureMeta={setPremiumFeatureMeta}
      />
    </TabPane>
  );

  const renderTeamsTab = () => {
    const { title, desc } = getTeamsEmptyScreenContent();

    return (
      <TabPane tab={UserAndTeamsTabs.Teams} key={UserAndTeamsTabs.Teams}>
        {hasPermission(Permissions.ACCOUNT_TEAM_MEMBER_READ) ||
        hasPermission(Permissions.TEAM_MEMBER_READ) ||
        hasPermission(Permissions.TEAM_TEAM_MEMBER_READ) ? (
          <>
            {!isGetTeamsRequestLoading && teams?.length === 0 && (
              <UsersAndTeamsEmptyScreen
                imrSrc={Images.UserWithBg}
                title={title}
                desc={desc}
              />
            )}

            <Teams
              activeTab={activeTab}
              addTeamModal={addTeamModal}
              showAddTeamModal={showAddTeamModal}
              hideAddTeamModal={hideAddTeamModal}
              isTeamsFilterDirty={isTeamsFilterDirty}
              setIsTeamsFilterDirty={setIsTeamsFilterDirty}
              tableRef={(refValue: any) => (teamTableRef.current = refValue)}
            />
          </>
        ) : (
          <TeamsPlanBasedRestriction
            adminFirstName={adminFirstName}
            userName={userName}
          />
        )}
      </TabPane>
    );
  };

  return (
    <>
      <UsersAndTeamsHeader />
      {subscription?.planCode === SubscriptionPlans.FREE && <HeaderBanner />}
      <GlobalSettings.Content className="users-and-teams--content">
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="steps"
          prefixCls="bs-tabs"
          className="bs-tabs-small users-and-teams--tabs"
          onChange={(key) => {
            setActiveTab(
              UserAndTeamsTabs.Users === key
                ? UserAndTeamsTabs.Users
                : UserAndTeamsTabs.Teams,
            );
            tableRef.current?.resetPagination();
            teamTableRef.current?.resetPagination();
          }}
          tabBarExtraContent={
            <UsersAndTeamsExtraContent
              activeTab={activeTab}
              filters={filters}
              onSearch={onSearch}
              onSearchChange={onSearchChange}
              onClearSearch={onClearSearch}
              showAddTeamModal={showAddTeamModal}
              showInviteUserModal={onInviteUserBtnClicked}
              isInviteUserModalBtnLoading={getIsRequestPending(
                getConnectedUsersAndEmailAccountsRequestStatus,
              )}
              isLoading={isGetUsersRequestLoading}
            />
          }
        >
          {hasPermission(Permissions.ACCOUNT_USER_READ) && renderUsersTab()}

          {hasPermission(Permissions.TEAM_SHOW) && renderTeamsTab()}
        </Tabs>

        <RestrictionErrorModal
          show={restrictionErrorModalMeta.show}
          onClose={() =>
            setRestrictionErrorModalMeta({
              ...restrictionErrorModalMeta,
              show: false,
            })
          }
          planName={restrictionErrorModalMeta.planName}
          restrictionOn="team members"
          feature="team members"
          firstName={adminFirstName}
        />

        <NoSeatAvailableForTeamModal
          show={noSeatAvailableForTeamModal}
          onClose={() => setNoSeatAvailableForTeamModal(false)}
          firstName={adminFirstName}
        />

        <PremiumFeatureModal
          show={premiumFeatureMeta.show}
          modalTitle={premiumFeatureMeta.title}
          bodyContent={premiumFeatureMeta.body}
          emailBody={basicTrailPlanRestriction()}
          onClose={hidePremiumFeatureModal}
        />
        {isRestrictionModalShow && (
          <PlanRestrictionErrorModal
            show={isRestrictionModalShow}
            modalTitle="Upgrade your plan to create team"
            bodyContent={[
              t('messages.team_create_msg_1'),
              t('messages.team_create_msg_2'),
            ]}
            emailBody={basicTrailPlanRestriction()}
            onClose={() => setIsRestrictionModalShow(false)}
            submitButtonText="Upgrade"
          />
        )}
      </GlobalSettings.Content>
    </>
  );
};

export default UsersAndTeamsContent;
