import React, { ReactNode, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Select from '../../design-system/components/select';
import WarningMessage from '../../design-system/components/atoms/warning-message';
import Modal from '../../design-system/components/atoms/modal';
import { RequestStatus } from '../../enums/request-status';
import { executeOnRequestStatus } from '../../utils';
import {
  OverlayTooltip,
  Placement,
} from '../../design-system/components/overlay';

type IProps = {
  show: boolean;
  contactSequenceList: any[];
  contactStepList: any[];
  isFetchingSequenceList: boolean;
  isSubmitRequestPending: boolean;
  isFetchingStepList: boolean;
  onSubmit: (selectedSequenceId, selectedStepId) => void;
  onHide: () => void;
  sendGetStepRequest: (selectedSequence) => void;
  sequence?: Record<string, number | string>;
  showWarning?: boolean;
  t: (x: string) => ReactNode;
  className?: string;
  icon?: any;
  submitRequestStatus: RequestStatus;
  currentSequenceId?: number;
};

type SequenceSelectDropDown = {
  key: number;
  name: string;
  id: number;
};
type StepSelectDropDown = SequenceSelectDropDown;

type GetSequenceType = {
  title: string;
  id: number;
};

const AddToSequenceModalDropDown: React.FC<IProps> = ({
  show,
  contactSequenceList,
  contactStepList,
  isFetchingSequenceList,
  isFetchingStepList,
  isSubmitRequestPending,
  onSubmit,
  onHide,
  sendGetStepRequest,
  sequence,
  showWarning,
  className,
  icon,
  t,
  submitRequestStatus,
  currentSequenceId,
}) => {
  const [selectedSequence, setSelectedSequence] = useState(0);
  const [selectedStep, setSelectedStep] = useState(0);

  useEffect(() => {
    if (selectedSequence !== 0) {
      setSelectedStep(0);
      sendGetStepRequest(selectedSequence);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSequence]);

  useEffect(() => {
    executeOnRequestStatus({
      status: submitRequestStatus,
      onFailed: () => {
        setSelectedSequence(0);
        setSelectedStep(0);
      },
    });
  }, [submitRequestStatus]);

  const sequenceSelect: SequenceSelectDropDown[] = contactSequenceList.map(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (sequence: GetSequenceType) => ({
      ...sequence,
      name: sequence.title,
      key: sequence.id,
    }),
  );

  const onAdd = () => {
    if (sequence && sequence.id) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const selectedStepId = stepSelect.find(
        (step) => step.key === selectedStep,
      )?.id;
      onSubmit(sequence.id, selectedStepId);
    } else {
      const selectedSequenceId = sequenceSelect.find(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (sequence) => sequence.key === selectedSequence,
      )?.id;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const selectedStepId = stepSelect.find(
        (step) => step.key === selectedStep,
      )?.id;
      onSubmit(selectedSequenceId, selectedStepId);
    }
  };

  let absoluteDay = 1;
  const stepSelect: StepSelectDropDown[] = contactStepList.map((step) => {
    absoluteDay += step.relativeDays;
    return {
      id: step.id,
      name: `Step ${step.number} - Day ${absoluteDay}`,
      key: step.id,
    };
  });
  return (
    <Modal
      show={show}
      className={`add-to-sequence-container add-sequence-prospect-modal ${className}`}
      titleContent={
        <div className="d-flex align-items-center">
          {icon && <div>{icon}</div>}
          <h2 className="ml-0">Add To Sequence</h2>
        </div>
      }
      onClose={onHide}
      onSubmit={onAdd}
      submitButtonText="Add"
      isSubmitDisabled={
        (sequence && sequence.id === 0 && selectedSequence === 0) ||
        selectedStep === 0 ||
        isSubmitRequestPending
      }
      isSubmitLoading={isSubmitRequestPending}
      backdrop="static"
      extraModalProps={{
        'aria-labelledby': 'contained-modal-title-vcenter',
        centered: true,
      }}
    >
      <>
        <div className="modal-text pb-1">Sequence</div>
        <Select<SequenceSelectDropDown>
          options={sequenceSelect}
          selectedOptionKey={selectedSequence}
          className="sequence-select-dropdown"
          optionRenderer={(option) => {
            if (currentSequenceId && option.id === currentSequenceId) {
              return (
                <OverlayTooltip
                  text="Current Sequence"
                  placement={Placement.Bottom}
                >
                  <div className="current-sequence">
                    <span>{option?.name}</span>
                    <span className="font-italic"> (Current Sequence)</span>
                  </div>
                </OverlayTooltip>
              );
            }
            return <span>{option?.name}</span>;
          }}
          showOptionsSeparator={true}
          selectedOptionRenderer={([option]) => <span>{option?.name}</span>}
          onChange={([option]) => setSelectedSequence(option.key)}
          placeholder={
            sequence && sequence.id ? `${sequence.name}` : 'Select Sequence'
          }
          disabled={isFetchingSequenceList || (sequence && !!sequence.id)}
        />
        {isFetchingSequenceList && (
          <div className="d-flex w-100 justify-content-center mt-2">
            <Spinner animation="border" size="sm" />
          </div>
        )}

        <div className="modal-text pb-1 mt-3">Sequence Steps</div>
        <Select<StepSelectDropDown>
          options={stepSelect}
          selectedOptionKey={selectedStep}
          optionRenderer={(option) => <span>{option?.name}</span>}
          showOptionsSeparator={true}
          selectedOptionRenderer={([option]) => <span>{option?.name}</span>}
          onChange={([option]) => setSelectedStep(option.key)}
          disabled={contactStepList.length === 0}
          placeholder="Select Step of Sequence"
        />
        {isFetchingStepList && (
          <div className="d-flex w-100 justify-content-center mt-2">
            <Spinner animation="border" size="sm" />
          </div>
        )}
        {showWarning && (
          <WarningMessage
            message={t(
              'messages.warning_cannot_add_to_sequence_search_and_add',
            )}
            className="align-items-center justify-content-left"
          />
        )}
      </>
    </Modal>
  );
};

export default withTranslation()(AddToSequenceModalDropDown);
