import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Images } from '../../../../shared/app-constants';
import ImageIcon from '../../../../shared/components/images/image-icon';
import TruncatedTextWithTooltip from '../../../../shared/components/truncated-text-with-tooltip';
import Banner from '../../../../shared/design-system/components/atoms/banner';
import Notification from '../../../../shared/design-system/components/atoms/notification';
import ProfileDropdown from '../../../../shared/design-system/components/atoms/profile-dropdown';
import EmailWarmupHeader from '../../../email-warmup/components/email-warmup-header';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import SequenceSingleHeader from '../../../sequence/components/sequence-single-header';
import ChangeLog from './components/change-log';
import { OverlayTooltip } from '../../../../shared/design-system/components/overlay/tooltip/overlay-tooltip';
import type { IProps } from './header-container';
import { getHeaderHelper, routeStateChange } from './header-helper';
import { AnalyticsEvents } from '../../../../shared/enums/analytics';
import HelpscoutBeaconEvents from '../../../../shared/enums/helpscout-beacon-events';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import { getEmailBody } from './utils/helper';
import {
  getIsRequestPending,
  redirectWithoutRefresh,
} from '../../../../shared/utils';
import { contactAdmin } from '../../../../shared/utils/contact-admin';
import { checkIsLtdPlan } from '../../../../shared/design-system/components/molecules/subscription-plan-details/helper/helper';
import { openChatSupport } from '../../../../shared/utils/open-chat-support';
import { IntercomEvents } from '../../../../shared/enums/intercom-events';
import {
  getAgencyUserRole,
  isAgencyUser,
  isWhitelabelWithClientView,
  isWhitelabelWithoutClientView,
} from '../../../../shared/utils/user-details';
import { UserRole } from '../../../../shared/enums/user-roles';
import { Routes as GlobalRoutes } from '../../../../shared/routes';
import HeaderBanner from './components/header-banner';
import { isInternalUrl } from '../../../../shared/utils/is-internal-url';

export enum Routes {
  SEQUENCE = 'sequence',
  TASKS = 'tasks',
  UNIFIED_INBOX = 'conversations',
  AGENCY_CLIENT = 'clients',
  PROSPECTS = 'prospects',
  TEMPLATES = 'templates',
  REPORTS = 'reports',
  EMAIL_INSIGHTS = 'email-insights',
  EMAIL_WARMUP = 'warmup',
  GROWTH_HUB = 'growth-hub',
  AGENCY_PORTAL = 'agency-portal',
  SETTINGS = 'settings',
  LEADS = 'leads',
}

const Header: React.FC<IProps> = ({
  location: { pathname },
  sendGetUserNotificationRequest,
  notifications,
  sendMarkNotificationAsReadRequest,
  match,
  pageHeaderBannerMessage,
  ctaText,
  ctaURL,
  bannerTextTwo,
  paymentActionType,
  target,
  notificationCount,
  markNotificationAsRead,
  userProfile,
  sendGetProfileRequest,
  getUserNotificationsRequestStatus,
  agencyConfig,
  sendGetUserUnreadNotification,
  unreadNotificationCount,
  showGettingStartedVideoModal,
  trackingId,
  planName,
  planType,
  planCode,
  remainingProspectLimit,
  remainingEmailSentLimit,
  getUserSettingsRequestStatus,
  meta,
  subscriptionDetails,
  firstName,
  resetNotificationData,
  getUserNotificationsDataRequestStatus,
  leadFinderCredits,
  client,
  getPostLoadMetaRequestStatus,
  agencyConfigRequestStatus,
  sendConnectEmailAccountRequest,
  sendGetSmtpImapAccountDetailsRequest,
  sendGetEmailAccountRequest,
  sendGetEmailAccountsRequest,
}) => {
  const { t } = useTranslation();
  const sequenceRoutes = [
    'steps',
    'prospects',
    'sequence-score',
    'settings',
    'emails',
  ];
  const emailWarmupRoutes = ['report'];

  const [headerTitle, setHeaderTitle] = useState('Sequence');
  const [singleSequenceHeader, setSingleSequenceHeader] = useState(false);
  const [emailWarmupHeader, setEmailWarmupHeader] = useState<boolean>(false);
  const [
    emailWarmupHeaderWithReport,
    setEmailWarmupHeaderWithReport,
  ] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [notificationId, setNotificationId] = useState<string>();

  const [subscriptionEndDays, setSubscriptionEndDays] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [isYoutubeIconVisible, setIsYoutubeIconVisible] = useState<boolean>(
    false,
  );

  const getHeader = (locationPathName) =>
    getHeaderHelper(locationPathName, Routes);

  const openHelpScoutBeacon = () => {
    openChatSupport();
  };

  const handleUpgradePlanCTA = (isPlanExpired = false) => {
    if (checkIsLtdPlan(planType)) {
      openHelpScoutBeacon();
      return;
    }

    const emailSubject = () => {
      if (isWhitelabelWithClientView()) {
        return isPlanExpired
          ? 'Request to upgrade our account.'
          : 'Request to upgrade plan';
      }

      return isPlanExpired
        ? t('labels.upgrade_to_saleshandy_email_sub')
        : t('messages.upgrade_plan_error_email_subject');
    };

    const emailBody = isPlanExpired
      ? `Hey ${firstName}, %0D%0A %0D%0A ${t(
          'messages.upgrade_to_pro_email_body',
        )}`
      : getEmailBody();

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      const path = pathname.split('/')?.[1];
      if (path === Routes.LEADS) {
        redirectWithoutRefresh(
          GlobalRoutes.Setting_Billing_Upgrade_Lead_Finder_Plan,
        );
      } else {
        redirectWithoutRefresh(
          GlobalRoutes.Setting_Billing_Upgrade_Email_Outreach_Plan,
        );
      }
    } else if (isAgencyUser()) {
      const agencyUserRole = getAgencyUserRole();
      const agencyEmailSubject = `${t(
        'messages.saleshandy_agency_account_upgrade_required',
      )}`;
      if (agencyUserRole && agencyUserRole === UserRole.AGENCYOWNER) {
        const supportEmail = process.env.REACT_APP_CONTACT_SUPPORT_EMAIL;

        contactAdmin(agencyEmailSubject, emailBody, supportEmail);
      }
      contactAdmin(agencyEmailSubject, emailBody);
    } else {
      contactAdmin(emailSubject(), emailBody);
    }
  };

  const setAnalyticsTrackData = () => {
    // Send data to analytics
    window.analytics?.track({
      userId: trackingId,
      event: AnalyticsEvents.BeaconOpened,
      properties: {
        current_tab_url: document.location.href,
        current_plan: planName,
      },
    });
  };

  useEffect(() => {
    setIsYoutubeIconVisible(
      agencyConfig?.showYTVideoGuide &&
        headerTitle?.trim()?.toLowerCase() === 'sequence' &&
        isWhitelabelWithoutClientView() &&
        (!getIsRequestPending(agencyConfigRequestStatus) || isInternalUrl()),
    );
  }, [headerTitle]);

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('on', HelpscoutBeaconEvents.OPEN, () => {
        setAnalyticsTrackData();
      });
    }
    if (window.Intercom) {
      window.Intercom(IntercomEvents.ON_SHOW, () => {
        setAnalyticsTrackData();
      });
    }
  }, [window.Beacon, window.Intercom]);

  useEffect(() => {
    // Initializing headwat change-log
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);

    const config = {
      selector: '#headway-badge',
      account: '7eoAWy',
      position: {
        y: 40,
        x: 0,
      },
    };
    script.onload = () => {
      window?.Headway?.init?.(config);
    };

    // Getting user profile
    sendGetProfileRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const path = pathname.split('/');

    routeStateChange({
      getHeader,
      path,
      Routes,
      sequenceRoutes,
      setSingleSequenceHeader,
      emailWarmupRoutes,
      setEmailWarmupHeader,
      setEmailWarmupHeaderWithReport,
      setHeaderTitle,
    });

    // Update Intercom
    if (window.Intercom) {
      window.Intercom(IntercomEvents.UPDATE);
    }
  }, [pathname, remainingProspectLimit, remainingEmailSentLimit]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserNotificationsRequestStatus,
      onSuccess: () => {
        markNotificationAsRead(notificationId);
      },
    });
  }, [getUserNotificationsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserSettingsRequestStatus,
      onSuccess: () => {
        setSubscriptionPlan(subscriptionDetails?.planCode);
        setSubscriptionEndDays(
          Math.ceil(
            DateTime.fromJSDate(new Date(subscriptionDetails?.nextBillingAt), {
              zone: 'Etc/UTC',
            })
              .diff(DateTime.now().toUTC(), ['days'])
              .toObject().days,
          ),
        );
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, subscriptionDetails]);

  const markNotificationsAsRead = async (
    notificationIdToMarkAsRead?: string,
  ) => {
    await sendMarkNotificationAsReadRequest(notificationIdToMarkAsRead);
    setNotificationId(notificationIdToMarkAsRead);
  };

  const fetchNextNotifications = () => {
    setPage(page + 1);
    sendGetUserNotificationRequest(page + 1);
  };
  const resetNotificationDataAndPage = () => {
    setPage(1);
    resetNotificationData();
    sendGetUserNotificationRequest(1);
  };
  // const showBetaTag = () => {
  //   const betaBadge = (
  //     <div className="d-flex justify-content-center align-items-center ml-2 beta-badge-blue">
  //       <span>Beta</span>
  //     </div>
  //   );

  //   const path = pathname.split('/')?.[1];
  //   if (path === Routes.EMAIL_INSIGHTS) {
  //     return betaBadge;
  //   }
  //   if (path === Routes.LEADS) {
  //     return betaBadge;
  //   }
  //   if (path === Routes.AGENCY_CLIENT) {
  //     return betaBadge;
  //   }
  //   return '';
  // };

  const getHeaderContent = () => {
    if (singleSequenceHeader) {
      return <SequenceSingleHeader currentTab={match.params.activeTab} />;
    }

    if (emailWarmupHeader) {
      return (
        <EmailWarmupHeader
          emailWarmupHeaderWithReport={emailWarmupHeaderWithReport}
        />
      );
    }

    return (
      <div className="d-flex align-items-center">
        <h2 className="semibold-3 mb-0 popover-arrow-color-txt">
          {headerTitle}
        </h2>
        {isYoutubeIconVisible && (
          <OverlayTooltip text="Learn how to create sequence.">
            <ImageIcon
              src={Images.Youtube}
              onClick={() => showGettingStartedVideoModal(true)}
              className="ml-2 pointer"
            />
          </OverlayTooltip>
        )}
      </div>
    );
  };

  return (
    <>
      <Row className="page-header">
        <Col md={4} className="p-0">
          <div className="d-flex header-with-notification">
            {getHeaderContent()}
          </div>
        </Col>
        <Col
          md={8}
          className="w-auto text-right justify-content-end align-items-center d-flex p-0"
        >
          <HeaderBanner
            subscriptionPlan={subscriptionPlan}
            subscriptionEndDays={subscriptionEndDays}
            pathname={pathname}
            leadFinderCredits={leadFinderCredits}
            handleUpgradePlanCTA={handleUpgradePlanCTA}
            isPostLoadMetaRequestPending={getIsRequestPending(
              getPostLoadMetaRequestStatus,
            )}
          />
          {hasPermission(Permissions.WHATSANEW_SHOW) && (
            <span className="ml-3">
              <ChangeLog />
            </span>
          )}
          <span className="ml-3">
            {hasPermission(Permissions.NOTIFICATION_READ) && (
              <Notification
                notifications={notifications}
                markAsRead={markNotificationsAsRead}
                notificationCount={notificationCount}
                fetchNextNotifications={fetchNextNotifications}
                timeZone={userProfile.timeZone}
                sendGetUserUnreadNotification={sendGetUserUnreadNotification}
                userUnreadNotificationCount={unreadNotificationCount}
                trackingId={trackingId}
                isHasPermissionToCallUnreadNotification={
                  planCode && hasPermission(Permissions.NOTIFICATION_READ)
                }
                resetNotificationData={resetNotificationDataAndPage}
                getUserNotificationsDataRequestStatus={
                  getUserNotificationsDataRequestStatus
                }
                sendConnectEmailAccountRequest={sendConnectEmailAccountRequest}
                sendGetSmtpImapAccountDetailsRequest={
                  sendGetSmtpImapAccountDetailsRequest
                }
                sendGetEmailAccountRequest={sendGetEmailAccountRequest}
                sendGetEmailAccountsRequest={sendGetEmailAccountsRequest}
              />
            )}
          </span>
          <span>
            <ProfileDropdown
              userProfile={userProfile}
              client={client}
              trackingId={trackingId}
            />
          </span>
          {agencyConfig?.showClientName && agencyConfig?.clientName && (
            <TruncatedTextWithTooltip
              string={agencyConfig?.clientName || ''}
              length={20}
              textClassName="agency--client-name font-14 font-semibold"
            />
          )}
          {!agencyConfig?.showClientName && client?.id && (
            <TruncatedTextWithTooltip
              string={client?.companyName || ''}
              length={20}
              textClassName="agency--client-name font-14 font-semibold"
            />
          )}
        </Col>
      </Row>
      {pageHeaderBannerMessage && (
        <div>
          <Banner
            pageHeaderBannerMessage={pageHeaderBannerMessage}
            style={{
              position: 'sticky',
              top: 0,
              marginBottom: '0%',
              borderRadius: '0%',
            }}
            ctaText={ctaText}
            ctaURL={ctaURL}
            target={target}
            bannerTextTwo={bannerTextTwo}
            paymentActionType={paymentActionType}
          />
        </div>
      )}
    </>
  );
};

export default Header;
