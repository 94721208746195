import { showLoading, hideLoading } from 'react-redux-loading-bar';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { CreateUpdateModalData, IProps, IState } from './types';
import store from '../../../../store';
import CustomFieldHeader from './components/custom-field-header';
import CustomFieldContent from './components/custom-field-content';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster from '../../../../shared/toaster';
import DeleteCustomFieldModal from './components/delete-custom-field-modal';
import { ProspectType } from '../../enums/prospect-type';
import CreateProspectCustomFieldModal from './components/generic-create-update-custom-field-modal/prospect-modal/create-prospect-custom-field-modal';
import UpdateProspectCustomFieldModal from './components/generic-create-update-custom-field-modal/prospect-modal/update-prospect-custom-field-modal';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';
import { FieldContentTabs } from './components/custom-field-content/types';
import { getIsRequestPending } from '../../../../shared/utils';

class CustomField extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      actionCustomField: null,
      showDeleteCustomFieldModal: false,
      showCreateProspectCustomFieldModal: false,
      showUpdateProspectCustomFieldModal: false,
      activeTabKey: FieldContentTabs.CustomFields,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    const {
      isLoading,
      createCustomFieldRequestStatus,
      updateCustomFieldRequestStatus,
      deleteCustomFieldRequestStatus,
    } = this.props;
    const { activeTabKey } = this.state;

    if (isLoading !== prevProps.isLoading) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isLoading ? store.dispatch(showLoading()) : store.dispatch(hideLoading());
    }

    if (
      createCustomFieldRequestStatus !==
      prevProps.createCustomFieldRequestStatus
    ) {
      this.createProspectCustomFieldRefresh();
    }

    if (
      updateCustomFieldRequestStatus !==
      prevProps.updateCustomFieldRequestStatus
    ) {
      this.updateProspectCustomFieldRefresh();
    }

    if (
      deleteCustomFieldRequestStatus !==
      prevProps.deleteCustomFieldRequestStatus
    ) {
      this.deleteCustomFieldRefresh();
    }

    if (activeTabKey !== prevState.activeTabKey) {
      this.getFields();
    }
  }

  componentWillUnmount = () => {
    const { resetCustomFieldsData } = this.props;
    resetCustomFieldsData();
  };

  onCreateProspectCustomFieldSubmit = (payload: CreateUpdateModalData) => {
    const { sendCreateCustomFieldRequest } = this.props;
    sendCreateCustomFieldRequest({
      prospectType: ProspectType.Contact,
      fieldType: payload.type,
      label: payload.label,
      fallbackText: payload.fallbackText ? payload.fallbackText : '',
    });
  };

  onUpdateProspectCustomFieldSubmit = (payload: CreateUpdateModalData) => {
    const { sendUpdateCustomFieldRequest } = this.props;
    const { actionCustomField } = this.state;
    sendUpdateCustomFieldRequest(actionCustomField.id, {
      label: payload.label,
      fallbackText: payload.fallbackText ? payload.fallbackText : '',
    });
  };

  showCreateProspectCustomFieldModal = () => {
    hasResource(ResourceIdentifiers.FIELDS_CREATE) &&
      this.setState({
        actionCustomField: null,
        showCreateProspectCustomFieldModal: true,
      });
  };

  hideCreateProspectCustomFieldModal = () => {
    this.setState({
      actionCustomField: null,
      showCreateProspectCustomFieldModal: false,
    });
  };

  showUpdateProspectCustomFieldModal = (customField) => {
    this.setState({
      actionCustomField: customField,
      showUpdateProspectCustomFieldModal: true,
    });
  };

  hideUpdateProspectCustomFieldModal = () => {
    this.setState({
      actionCustomField: null,
      showUpdateProspectCustomFieldModal: false,
    });
  };

  showProspectCustomFieldDeleteModal = (customField) => {
    this.setState({
      actionCustomField: customField,
      showDeleteCustomFieldModal: true,
    });
  };

  showAccountCustomFieldDeleteModal = (customField) => {
    this.setState({
      actionCustomField: customField,
      showDeleteCustomFieldModal: true,
    });
  };

  hideDeleteCustomFieldModal = () => {
    this.setState({
      actionCustomField: null,
      showDeleteCustomFieldModal: false,
    });
  };

  setActiveTabKey = (activeTabKey: string) => {
    this.setState({
      activeTabKey,
    });
  };

  onDeleteCustomFieldSubmit = () => {
    const { sendDeleteCustomFieldRequest } = this.props;
    const { actionCustomField } = this.state;
    sendDeleteCustomFieldRequest(actionCustomField.id);
  };

  componentDidMount = () => {
    this.getFields();
  };

  getFields = (forceRefetch = false) => {
    const {
      customField,
      defaultField,
      sendGetCustomFieldRequest,
      sendGetDefaultFieldRequest,
      customFieldPagination: {
        options: { limit: cL, page: cP },
      },
      defaultFieldPagination: {
        options: { limit: dL, page: dP },
      },
    } = this.props;
    const { activeTabKey } = this.state;
    if (
      activeTabKey === FieldContentTabs.CustomFields &&
      (customField.length <= 0 || forceRefetch)
    ) {
      sendGetCustomFieldRequest({ limit: cL, page: cP });
    } else if (
      activeTabKey === FieldContentTabs.SystemFields &&
      (defaultField.length <= 0 || forceRefetch)
    ) {
      sendGetDefaultFieldRequest({ limit: dL, page: dP });
    }
  };

  getPageNumber = () => {
    const {
      customField,
      customFieldPagination: {
        options: { page: cP },
      },
    } = this.props;

    if (cP > 1 && customField.length === 1) {
      return cP - 1;
    }

    return cP;
  };

  createProspectCustomFieldRefresh = () => {
    const {
      createCustomFieldRequestStatus,
      createCustomFieldRequestMessage,
      createCustomFieldRequestError,
      sendGetCustomFieldRequest,
      customFieldPagination: {
        options: { limit: cL, page: cP },
      },
    } = this.props;

    if (createCustomFieldRequestStatus === RequestStatus.Succeeded) {
      toaster.success(createCustomFieldRequestMessage);
      this.hideCreateProspectCustomFieldModal();
      sendGetCustomFieldRequest({ limit: cL, page: cP });
    }
    if (
      createCustomFieldRequestStatus === RequestStatus.Failed &&
      createCustomFieldRequestError
    ) {
      toaster.error(createCustomFieldRequestError.message);
    }
  };

  updateProspectCustomFieldRefresh = () => {
    const {
      updateCustomFieldRequestStatus,
      updateCustomFieldRequestError,
    } = this.props;

    if (updateCustomFieldRequestStatus === RequestStatus.Succeeded) {
      this.showUpdateFieldSuccessToaster();
      this.hideUpdateProspectCustomFieldModal();
      this.getFields(true);
    }

    if (
      updateCustomFieldRequestStatus === RequestStatus.Failed &&
      updateCustomFieldRequestError
    ) {
      toaster.error(updateCustomFieldRequestError.message);
    }
  };

  showUpdateFieldSuccessToaster = () => {
    const { actionCustomField } = this.state;
    const { t } = this.props;
    if (actionCustomField?.isDefault) {
      toaster.success(t('messages.system_field_update_successfully'));
    } else {
      toaster.success(t('messages.custom_field_update_successfully'));
    }
  };

  deleteCustomFieldRefresh = () => {
    const {
      deleteCustomFieldRequestStatus,
      deleteCustomFieldRequestMessage,
      deleteCustomFieldRequestError,
      sendGetCustomFieldRequest,
      customFieldPagination: {
        options: { limit: cL },
      },
    } = this.props;

    if (deleteCustomFieldRequestStatus === RequestStatus.Succeeded) {
      toaster.success(deleteCustomFieldRequestMessage);
      this.hideDeleteCustomFieldModal();
      sendGetCustomFieldRequest({ limit: cL, page: this.getPageNumber() });
    }

    if (
      deleteCustomFieldRequestStatus === RequestStatus.Failed &&
      deleteCustomFieldRequestError
    ) {
      toaster.error(deleteCustomFieldRequestError.message);
    }
  };

  render() {
    const {
      customField,
      defaultField,
      getCustomFieldRequestStatus,
      getDefaultFieldRequestStatus,
      createCustomFieldRequestStatus,
      updateCustomFieldRequestStatus,
      deleteCustomFieldRequestStatus,
      sendGetCustomFieldRequest,
      sendGetDefaultFieldRequest,
      customFieldPagination,
      defaultFieldPagination,
      subscriptionPlan,
    } = this.props;

    const {
      actionCustomField,
      showCreateProspectCustomFieldModal,
      showUpdateProspectCustomFieldModal,
      showDeleteCustomFieldModal,
    } = this.state;

    const isCreateRequestPending =
      createCustomFieldRequestStatus === RequestStatus.Pending;

    const isUpdateRequestPending =
      updateCustomFieldRequestStatus === RequestStatus.Pending;

    const isDeleteRequestPending =
      deleteCustomFieldRequestStatus === RequestStatus.Pending;

    return (
      <div className="custom-fields-container">
        <div className="custom-fields-grid bs-mb-30">
          <CustomFieldHeader headerName="Prospect Fields" />
          <CustomFieldContent
            isRequestPending={
              getIsRequestPending(getCustomFieldRequestStatus) ||
              getIsRequestPending(getDefaultFieldRequestStatus)
            }
            sendGetCustomFieldsRequest={sendGetCustomFieldRequest}
            sendGetDefaultFieldsRequest={sendGetDefaultFieldRequest}
            customFields={customField}
            defaultFields={defaultField}
            customFieldPagination={customFieldPagination}
            defaultFieldPagination={defaultFieldPagination}
            onCreateHandler={this.showCreateProspectCustomFieldModal}
            onUpdateHandler={this.showUpdateProspectCustomFieldModal}
            onDeleteHandler={this.showProspectCustomFieldDeleteModal}
            subscriptionPlan={subscriptionPlan}
            activeTabKey={this.state.activeTabKey}
            setActiveTabKey={this.setActiveTabKey}
          />
        </div>
        {showCreateProspectCustomFieldModal && (
          <CreateProspectCustomFieldModal
            show={showCreateProspectCustomFieldModal}
            onClose={this.hideCreateProspectCustomFieldModal}
            onSave={this.onCreateProspectCustomFieldSubmit}
            isRequestPending={isCreateRequestPending}
          />
        )}
        {showUpdateProspectCustomFieldModal && (
          <UpdateProspectCustomFieldModal
            show={showUpdateProspectCustomFieldModal}
            onClose={this.hideUpdateProspectCustomFieldModal}
            onSave={this.onUpdateProspectCustomFieldSubmit}
            isRequestPending={isUpdateRequestPending}
            customField={actionCustomField}
          />
        )}
        {showDeleteCustomFieldModal && (
          <DeleteCustomFieldModal
            title="Do you want to delete this custom field?"
            content=" If you delete the custom field, it can hinder the personalization of
            emails."
            show={showDeleteCustomFieldModal}
            onClose={this.hideDeleteCustomFieldModal}
            onSubmit={this.onDeleteCustomFieldSubmit}
            isRequestPending={isDeleteRequestPending}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomField);
